

class CardStyler {
  constructor() {
    this.allCardsOnDisplayLength = 0;
  }

  init(carouselInstance) {
    this.carouselInstance = carouselInstance;
    window.addEventListener('resize', () => {
      this.setupCardsStyles();
      this.setPaddingCard();
    });
    this.setupNavButton('btn_nav_cards-fibra', 'fibra');
    this.setupNavButton('btn_nav_cards-fibra-globoplay', 'streaming');
    this.setupNavButton('btn_nav_cards-fibra-wifi', 'alto-valor');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const offerTab = urlParams.get('offerTab');
      if (!offerTab) {
        const navBtnFibra = document.getElementById('btn_nav_cards-fibra');
    this.settings('fibra', navBtnFibra);

      }
  }

  setupCardsStyles() {
    const cards = document.querySelectorAll('.offers-cards__card');
    const isWideScreenWithManyCards = window.innerWidth >= 1364 && this.allCardsOnDisplayLength > 4;

    cards.forEach((card) => {
      if (this.isCardDisplayed(card)) {
        const elements = this.getCardElements(card);
        this.applyStyles(elements, isWideScreenWithManyCards);
      }
    });
  }

  isCardDisplayed(card) {
    const computedStyle = window.getComputedStyle(card);
    return computedStyle.display === 'block';
  }

  getCardElements(card) {
    return {
      cardWrapper: card.querySelector('.offers-cards__card__wrapper'),
      cardBody: card.querySelector('.offers-cards__card__body'),
      cardFooter: card.querySelector('.offers-cards__card__footer'),
      subtitleStreamingFooter: card.querySelector('.offers-cards__card__footer-item--describe-subtitle--streaming')
    };
  }

  applyStyles(elements, isCompact) {
    const { cardWrapper, cardBody, cardFooter, subtitleStreamingFooter } = elements;
    if (isCompact) {
      this.setCompactStyles({ cardWrapper, cardBody, cardFooter, subtitleStreamingFooter });
    } else {
      this.setDefaultStyles({ cardWrapper, cardBody, cardFooter, subtitleStreamingFooter });
    }
  }

  setCompactStyles({ cardWrapper, cardBody, cardFooter, subtitleStreamingFooter }) {
    cardWrapper.style.width = '241px';
    cardWrapper.style.minWidth = 'unset';
    cardBody.style.paddingInline = '16px';
    if (cardFooter) cardFooter.style.paddingInline = '16px';
    if (subtitleStreamingFooter) subtitleStreamingFooter.style.display = 'none';
  }

  setDefaultStyles({ cardWrapper, cardBody, cardFooter, subtitleStreamingFooter }) {
    cardWrapper.style.width = '';
    cardWrapper.style.minWidth = '';
    cardBody.style.paddingInline = '';
    if (cardFooter) cardFooter.style.paddingInline = '';
    if (subtitleStreamingFooter) subtitleStreamingFooter.style.display = 'block';
  }

  updateDisplayLength(length) {
    this.allCardsOnDisplayLength = length;
  }

  setupNavButton(buttonId, cardContext) {
    const button = document.getElementById(buttonId);
    button?.addEventListener('click', () => this.settings(cardContext, button));
  }

  settings(cardContext, button) {
    this.handleClearCards();
    this.handleClearActiveNavCard();
    button?.setAttribute('data-nav-state', 'active');
    const allCardsOnDisplay = document.querySelectorAll(
      `#offers-cards-container [data-card-context="${cardContext}"]`
    );
    this.updateDisplayLength(allCardsOnDisplay.length);
    allCardsOnDisplay.forEach((card, index) => {
      card.style.display = 'block';
      if(window.innerWidth > 1364 && index === 0){
        card.style.paddingLeft = '0px';
      }
    });

    this.setupCardsStyles();
    if (this.carouselInstance.carousel) {
    this.carouselInstance.carousel.properties = this.carouselInstance.carousel.getContainerProperties();
    this.carouselInstance.carousel.setCurrentPositionByIndex(0);
    this.carouselInstance.renderDots();
    }
  }

  handleClearCards() {
    const cards = document.querySelectorAll('.offers-cards__card');
    cards.forEach((card) => {
      card.style.display = 'none';
    });
  }

  handleClearActiveNavCard() {
    const activeButtons = document.querySelectorAll('[data-nav-state="active"]');
    activeButtons.forEach((button) => {
      button.removeAttribute('data-nav-state');
    });
  }

  setPaddingCard() {
    const containerElement = document.getElementById('offers-cards-container');
    this.children = document.querySelectorAll('.offers-cards__card');

    if(!this.children[0] || !containerElement){return;}
  this.children[0].style.paddingLeft = '0';

    

    if (window.innerWidth > 1364) {
      setCardsLeftPaddingValue('offers-cards-container', 'offers-cards-info');
    } else if (window.innerWidth > 1124) {
      containerElement.style.paddingLeft = '36px';
    } else if (window.innerWidth > 768) {
      containerElement.style.paddingLeft = '24px';
    } else {
      containerElement.removeAttribute('style');
    }
  }
}
const cardStyler = new CardStyler();
const carouselOffersCards = new CarouselOffersCards('generic-card-dots', cardStyler);
cardStyler.init(carouselOffersCards);

// Adiciona Scroll em desktop com o mouse
const scrollContainer = document.getElementById('offers-cards-container');

let isDown = false;
let startX;
let scrollLeft;

scrollContainer?.addEventListener('mousedown', (e) => {
  isDown = true;
  scrollContainer.classList.add('active');
  startX = e.pageX - scrollContainer.offsetLeft;
  scrollLeft = scrollContainer.scrollLeft;
});

scrollContainer?.addEventListener('mouseleave', () => {
  isDown = false;
  scrollContainer.classList.remove('active');
  scrollContainer.style.scrollBehavior = 'smooth';
});

scrollContainer?.addEventListener('mouseup', () => {
  isDown = false;
  scrollContainer.classList.remove('active');
  scrollContainer.style.scrollBehavior = 'smooth';
});

scrollContainer?.addEventListener('mousemove', (e) => {
  if (!isDown) return;
  e.preventDefault();
  const x = e.pageX - scrollContainer.offsetLeft;
  scrollContainer.style.scrollBehavior = 'unset';
  const walk = (x - startX) * 3; // O multiplicador de velocidade de scroll
  scrollContainer.scrollLeft = scrollLeft - walk;
});

// Ajusta as tags especiais
const handleAdjustSpecialTag = () => {
  document.querySelectorAll('.offers-cards__card').forEach((card) => {
    const cardTagSpecial = card.querySelector('.offers-cards__tag-special');
    const cardWrapperElement = card.querySelector('.offers-cards__card__wrapper');

    if (cardWrapperElement) {
      cardWrapperElement.style.marginTop = cardTagSpecial ? '-16px' : window.innerWidth >= 768 ? '18px' : '';
    }
  });
};

handleAdjustSpecialTag();
window.addEventListener('resize', handleAdjustSpecialTag);

// Ajustar a contagem de Wi-Fi
const changeCountWifi = (button) => {
  const container = button.parentElement;
  const textElement = container.querySelector('span');
  let amount = parseInt(textElement.getAttribute('data-wifi-amount'));
  button.getAttribute('data-wifi-btn') === 'sub' ? amount-- : amount++;
  textElement.textContent = amount;
  textElement.setAttribute('data-wifi-amount', amount);

  const btnAdd = container.querySelector('[data-wifi-btn="add"]');
  const btnSub = container.querySelector('[data-wifi-btn="sub"]');

  amount === 3 ? btnAdd.setAttribute('disabled', true) : btnAdd.removeAttribute('disabled');
  amount === 1 ? btnSub.setAttribute('disabled', true) : btnSub.removeAttribute('disabled');
};

const allBtnCountWifi = document.querySelectorAll('[data-wifi-btn]');
allBtnCountWifi.forEach((btn) => btn.addEventListener('click', function () {
  changeCountWifi(this);
}));

